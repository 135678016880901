import React from 'react';
import Popover from '@material-ui/core/Popover';

export function EmojiPicker(props: any) {
    const onEmojiClick = (emojiObject: any) => {
        if (props.onPick) {
            props.onPick(emojiObject);
        }
    };

    const emojis = require('emojis-list');
    return (
        <Popover
            id={'color-picker'}
            open={true}
            onClose={() => props.onPick(null)}
            anchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
            <div
                className='emojis-list'
                style={{ fontSize: '2em', display: 'inline-grid', gridTemplateColumns: 'auto auto auto auto' }}>
                {emojis.map((emoji: string, i: number) => (
                    <span
                        style={{ textAlign: 'center' }}
                        role='img'
                        aria-label='emoji'
                        key={i}
                        onClick={() => onEmojiClick(emoji)}>
                        {emoji} &nbsp;
                    </span>
                ))}
            </div>
        </Popover>
    );
    // <Picker onEmojiClick={onEmojiClick} disableSearchBar={true} />
}
