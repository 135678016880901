import React, { useState } from 'react';
import firebase from 'firebase';
import { Player } from '../../App';
import './Malefiz.css';
import MiniChat from '../minichat/MiniChat';

interface FieldType {
    id: string;
    figure?: { gamePlayerId: number; figureId: number };
    barrierId?: number;
}

interface GamePlayer {
    playerId: string;
    figures: string[];
    barrierBank?: number;
}

interface MalefizGame {
    id?: string;
    started?: boolean;
    ended?: boolean;
    created: string;
    winner: string;
    players: GamePlayer[];
    barriers: string[];
    turn: number;
    die: number;
}

interface MalefizProps {
    db: firebase.firestore.Firestore;
    player: Player;
    players: Player[];
}

interface MalefizState {
    games: MalefizGame[];
    openGame: MalefizGame | undefined;
    zoom: string;
}

class Malefiz extends React.Component<MalefizProps, MalefizState> {
    unsubGames: any;

    constructor(props: MalefizProps) {
        super(props);
        this.state = {
            games: [],
            openGame: undefined,
            zoom: 'zoom100',
        };
    }

    componentDidMount() {
        this.unsubGames = this.props.db
            .collection('games/malefiz/games')
            .orderBy('created', 'desc')
            .limit(100)
            .onSnapshot((sspgames) => {
                const gamesArray = sspgames.docs.map((v, i) => {
                    return { ...{ id: v.id }, ...v.data() };
                }) as MalefizGame[];
                console.log('onSnapshot games:', gamesArray);
                const openGame = gamesArray.find((row: any) => !row.ended) as MalefizGame | undefined;
                this.setState({ openGame: openGame, games: gamesArray });
            });
    }

    componentWillUnmount() {
        this.unsubGames();
    }

    getPlayer(playerId: string) {
        const player = this.props.players.find((player: any) => player.id === playerId) as Player;
        if (player) {
            return (
                <span>
                    {player.avatar} {player.name}
                </span>
            );
        } else {
            return null;
        }
    }

    createGame() {
        const newGame: MalefizGame = {
            winner: '',
            created: new Date().toISOString(),
            players: [
                { playerId: '', figures: ['home33', 'home22', 'home23', 'home24', 'home13'] },
                { playerId: '', figures: ['home37', 'home26', 'home27', 'home28', 'home17'] },
                { playerId: '', figures: ['home311', 'home210', 'home211', 'home212', 'home111'] },
                { playerId: '', figures: ['home315', 'home214', 'home215', 'home216', 'home115'] },
            ],
            barriers: ['c1', 'c5', 'c9', 'c13', 'c17', 'g7', 'g11', 'i9', 'j9', 'k9'],
            turn: 0,
            die: 0,
        };
        this.props.db.collection('games/malefiz/games').add(newGame);
    }

    updateGame(openGame: MalefizGame) {
        console.log('updateGame', openGame);
        const id = openGame.id;
        delete openGame.id;
        if (id) {
            this.props.db.collection('games/malefiz/games').doc(id).update(openGame);
        } else {
            throw new Error('ID MISSING!!');
        }
    }

    updatePlayer(player: Player) {
        console.log('updatePlayer', player);
        const id = player.id;
        delete player.id;
        if (id) {
            this.props.db.collection('players').doc(id).update(player);
        } else {
            throw new Error('ID MISSING!!');
        }
    }

    myGamePlayerId() {
        return this.state.openGame?.players.findIndex((p) => p.playerId === this.props.player.id);
    }

    toggleZoom() {
        this.setState({ zoom: this.state.zoom === 'zoom100' ? 'zoom50' : 'zoom100' });
    }

    throwDice() {
        const openGame = JSON.parse(JSON.stringify(this.state.openGame));
        if (openGame && (!openGame.die || openGame.die === 6)) {
            openGame.die = Math.floor(Math.random() * 6) + 1;
            this.updateGame(openGame);
        }
    }

    nextPlayer(nextId: number) {
        let nexturn = nextId;
        const openGame = JSON.parse(JSON.stringify(this.state.openGame));
        if (openGame) {
            if (openGame.players.length <= nexturn) {
                nexturn = 0;
            }
            if (openGame.players[nexturn].playerId) {
                openGame.turn = nexturn;
                openGame.die = 0;
                this.updateGame(openGame);
            } else {
                this.nextPlayer(nexturn + 1);
            }
        }
    }

    addPlayer(gamePlayerId: number) {
        const openGame = JSON.parse(JSON.stringify(this.state.openGame)) as MalefizGame;
        if (openGame && this.props.player.id) {
            const index = openGame.players.findIndex((p) => p.playerId === this.props.player.id);
            if (index >= 0 && index < openGame.players.length) {
                openGame.players[index].playerId = '';
            }
            if (gamePlayerId !== index) {
                openGame.players[gamePlayerId].playerId = this.props.player.id;
            }
            this.updateGame(openGame);
        }
    }

    startGame() {
        const openGame = JSON.parse(JSON.stringify(this.state.openGame));
        if (openGame) {
            openGame.started = true;
            this.updateGame(openGame);
        }
    }

    endGame(winner: string) {
        const openGame = JSON.parse(JSON.stringify(this.state.openGame));
        if (openGame) {
            openGame.winner = winner;
            openGame.ended = true;

            const playerCopy = JSON.parse(JSON.stringify(this.props.players.find((p) => p.id === winner)));
            playerCopy.gamesWon = playerCopy.gamesWon ? playerCopy.gamesWon + 1 : 1;
            this.updatePlayer(playerCopy);
            this.updateGame(openGame);
        }
    }

    joinLater(playerId: string | undefined, gamePlayerId: number) {
        const openGame = JSON.parse(JSON.stringify(this.state.openGame)) as MalefizGame;
        if (openGame) {
            const index = openGame.players.findIndex((p) => p.playerId === playerId);
            if (index < 0 && playerId) {
                openGame.players[gamePlayerId].playerId = playerId;
                this.updateGame(openGame);
            }
        }
    }

    giveUp(playerId: string) {
        const openGame = JSON.parse(JSON.stringify(this.state.openGame)) as MalefizGame;
        if (openGame) {
            const index = openGame.players.findIndex((p) => p.playerId === playerId);
            openGame.players[index].playerId = '';
        }
        const leftPlayers = openGame.players.filter((p) => p.playerId);
        if (leftPlayers.length === 1) {
            this.endGame(leftPlayers[0].playerId);
        } else {
            this.updateGame(openGame);
        }
    }

    render() {
        return (
            <div>
                <MiniChat
                    db={this.props.db}
                    player={this.props.player}
                    players={this.props.players}
                    gameChatUrl={'games/malefiz/chat'}
                />
                <div className='mastermind' style={{ padding: '10px' }}>
                    <h3 style={{ textAlign: 'center' }}>
                        👧&nbsp;👨&nbsp;👩&nbsp;👦
                        <br />
                        Malefiz
                    </h3>
                </div>
                {this.state.openGame ? (
                    <div>
                        {this.state.openGame.started !== true ? (
                            <div style={{ padding: '10px' }}>
                                <div>
                                    {this.state.openGame.players.map((p, gamePlayerId) => {
                                        if (p.playerId) {
                                            return (
                                                <div>
                                                    {this.getPlayer(p.playerId)}
                                                    <button onClick={() => this.addPlayer(gamePlayerId)}>
                                                        austreten
                                                    </button>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div>
                                                    Spieler {gamePlayerId}{' '}
                                                    <button onClick={() => this.addPlayer(gamePlayerId)}>
                                                        beitreten
                                                    </button>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                                {this.state.openGame.players.filter((p) => p.playerId).length > 1 && (
                                    <button onClick={() => this.startGame()}>Spiel starten</button>
                                )}
                            </div>
                        ) : (
                            <div>
                                <Winner
                                    openGame={this.state.openGame}
                                    getPlayer={(playerId: any) => this.getPlayer(playerId)}
                                    endGame={(winner: any) => this.endGame(winner)}
                                />
                                <div>
                                    <div className='game-toolbar'>
                                        <div>
                                            {this.state.openGame.players.map((gamePlayer, index) => {
                                                if (this.state.openGame) {
                                                    if (gamePlayer.playerId) {
                                                        return (
                                                            <div
                                                                className={
                                                                    this.state.openGame.turn === index ? 'bold' : ''
                                                                }>
                                                                <span>{<Figure gamePlayerId={index} />}</span>{' '}
                                                                {this.getPlayer(gamePlayer.playerId)}{' '}
                                                                {this.props.player?.id === gamePlayer.playerId && (
                                                                    <button
                                                                        onClick={() =>
                                                                            this.giveUp(gamePlayer.playerId)
                                                                        }>
                                                                        aufgeben
                                                                    </button>
                                                                )}
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <div
                                                                className={
                                                                    this.state.openGame.turn === index ? 'bold' : ''
                                                                }>
                                                                <span>{<Figure gamePlayerId={index} />}</span> Frei{' '}
                                                                <button
                                                                    onClick={() =>
                                                                        this.joinLater(this.props.player?.id, index)
                                                                    }>
                                                                    beitreten
                                                                </button>
                                                            </div>
                                                        );
                                                    }
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div className='game-toolbar'>
                                        <div
                                            className={
                                                'tool ' + (this.state.zoom === 'zoom100' ? 'unpressed' : 'pressed')
                                            }
                                            onClick={() => this.toggleZoom()}
                                            style={{ fontSize: '2em' }}>
                                            {this.state.zoom === 'zoom100' ? <span>🐋</span> : <span>🐜</span>}
                                        </div>
                                        {this.state.openGame.players[this.state.openGame.turn].playerId ===
                                            this.props.player.id && (
                                            <div
                                                className={
                                                    'tool ' +
                                                    (this.state.openGame.die > 0 && this.state.openGame.die < 6
                                                        ? 'pressed'
                                                        : 'unpressed')
                                                }
                                                onClick={() => this.throwDice()}
                                                style={{ fontSize: '2em' }}>
                                                🎲
                                            </div>
                                        )}
                                        <div className={'tool '} style={{ fontSize: '2em' }}>
                                            {this.state.openGame.die > 0 ? (
                                                <span>{this.state.openGame.die}</span>
                                            ) : (
                                                <span>?</span>
                                            )}
                                        </div>

                                        {this.state.openGame.players[this.state.openGame.turn].playerId ===
                                            this.props.player.id &&
                                            this.state.openGame.die > 0 && (
                                                <div
                                                    className={'tool '}
                                                    onClick={() =>
                                                        this.nextPlayer(
                                                            this.state.openGame ? this.state.openGame.turn + 1 : 0
                                                        )
                                                    }
                                                    style={{ fontSize: '2em' }}>
                                                    ⏭️
                                                </div>
                                            )}
                                        {this.state.openGame.players[this.state.openGame.turn].barrierBank !==
                                            undefined && (
                                            <div style={{ padding: '10px' }}>
                                                <Barrier
                                                    barrierIndex={
                                                        this.state.openGame.players[this.state.openGame.turn]
                                                            .barrierBank
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='board-wrapper'>
                                    <Board
                                        zoom={this.state.zoom}
                                        openGame={this.state.openGame}
                                        gamePlayerId={this.myGamePlayerId()}
                                        updateGame={(openGame: any) => this.updateGame(openGame)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <br />
                        <NewGame newGameCallback={() => this.createGame()} />
                        <br />
                        <AllGames games={this.state.games} players={this.props.players} />
                    </div>
                )}
            </div>
        );
    }
}

function NewGame(props: any) {
    return (
        <div style={{ textAlign: 'center' }}>
            <button onClick={() => props.newGameCallback()}>New Game</button>
        </div>
    );
}

function AllGames(props: { games: MalefizGame[]; players: Player[] }) {
    const player = (playerId: string | undefined, avatar: boolean = false) => {
        const player = props.players.find((p) => p.id === playerId);
        return (
            <span>
                {avatar && player?.avatar} {player?.name}
            </span>
        );
    };

    const datePrint = (datestring: string) => {
        const date = new Date(datestring);
        return date.toLocaleDateString() + ' ' + date.getHours() + ':' + date.getMinutes() + ' Uhr';
    };

    return (
        <div>
            <h5>Gewinner:</h5>
            {props.games.map((game) => (
                <div key={game.id}>
                    <hr />
                    <div>
                        <span style={{ display: 'inline-block' }}>
                            <b>
                                {props.players.map((p) => {
                                    <span key={p.id}>{player(p.id)}</span>;
                                })}
                            </b>
                        </span>
                        <br />
                        <span style={{ display: 'inline-block', width: '50%' }}>{datePrint(game.created)}</span>
                        <span style={{ textAlign: 'right', display: 'inline-block', width: '50%', fontSize: '1.3em' }}>
                            {player(game.winner, true)} 🏅
                        </span>
                    </div>
                </div>
            ))}
            <hr />
        </div>
    );
}

const rows: string[] = [
    'n',
    'm',
    'l',
    'k',
    'j',
    'i',
    'h',
    'g',
    'f',
    'e',
    'd',
    'c',
    'b',
    'a',
    'home3',
    'home2',
    'home1',
];
const cols: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];

function Board(props: { zoom: string; openGame: MalefizGame; gamePlayerId: number | undefined; updateGame: any }) {
    const [selectedField, setSelectedField] = useState<FieldType | null>(null);
    console.log('render Board. selectedField:', selectedField);

    const selectField = (fieldProps: FieldType) => {
        console.log('selectField', fieldProps);
        if (props.gamePlayerId === props.openGame.turn) {
            const barrierId = props.openGame.players[props.openGame.turn].barrierBank;
            if (barrierId !== undefined) {
                console.log('Has barrier in bank');
                if (fieldProps && fieldProps.barrierId === undefined && fieldProps.figure === undefined) {
                    const openGame = JSON.parse(JSON.stringify(props.openGame)) as MalefizGame;
                    openGame.barriers[barrierId] = fieldProps.id;
                    delete openGame.players[props.openGame.turn].barrierBank;
                    console.log('save barriere bank', openGame);
                    setSelectedField(null);
                    props.updateGame(openGame);
                }
            } else {
                if (fieldProps.figure) {
                    console.log('fieldProps.figure', fieldProps.figure);
                    if (selectedField?.figure) {
                        if (fieldProps.figure.gamePlayerId === selectedField?.figure.gamePlayerId) {
                            if (fieldProps.figure.figureId === selectedField?.figure.figureId) {
                                setSelectedField(null); // same player, same figure, deselect
                            } else {
                                setSelectedField(fieldProps); // same player, just select other
                            }
                        } else {
                            const openGame = JSON.parse(JSON.stringify(props.openGame));
                            // send other home
                            openGame.players[fieldProps.figure.gamePlayerId].figures[fieldProps.figure.figureId] =
                                playerFields[fieldProps.figure.gamePlayerId][0];
                            // save new position
                            openGame.players[selectedField.figure.gamePlayerId].figures[selectedField.figure.figureId] =
                                fieldProps.id;
                            setSelectedField(null);
                            props.updateGame(openGame);
                        }
                    } else if (selectedField?.barrierId) {
                        // barrier then figure.
                        // setSelectedField(null); // just deselect all
                    } else {
                        if (props.gamePlayerId === fieldProps.figure.gamePlayerId) {
                            // empty field was before selected
                            setSelectedField(fieldProps);
                        }
                    }
                } else if (fieldProps.barrierId !== undefined) {
                    console.log('fieldProps.barrierId', fieldProps.barrierId);
                    if (selectedField?.figure) {
                        const openGame = JSON.parse(JSON.stringify(props.openGame)) as MalefizGame;
                        openGame.players[selectedField.figure.gamePlayerId].figures[selectedField.figure.figureId] =
                            fieldProps.id;
                        openGame.barriers[fieldProps.barrierId] = '';
                        openGame.players[selectedField.figure.gamePlayerId].barrierBank = fieldProps.barrierId;
                        console.log('save barriere bank', openGame);
                        setSelectedField(null);
                        props.updateGame(openGame);
                    }
                } else if (fieldProps) {
                    console.log('fieldProps', fieldProps);
                    if (selectedField?.figure) {
                        const openGame = JSON.parse(JSON.stringify(props.openGame));
                        // save new position
                        openGame.players[selectedField.figure.gamePlayerId].figures[selectedField.figure.figureId] =
                            fieldProps.id;
                        setSelectedField(null);
                        props.updateGame(openGame);
                    } else if (selectedField?.barrierId) {
                        const openGame = JSON.parse(JSON.stringify(props.openGame));
                        openGame.barriers[selectedField?.barrierId] = fieldProps.id;
                        setSelectedField(null);
                        props.updateGame(openGame);
                    } else if (selectedField) {
                        setSelectedField(null);
                    } else {
                        setSelectedField(fieldProps);
                    }
                }
            }
        }
    };

    return (
        <div className={'malefiz-board ' + props.zoom}>
            {rows.map((row) => {
                return (
                    <div className='board-row'>
                        {cols.map((col) => {
                            const fieldId = row + col;
                            return (
                                <div className='board-col'>
                                    <Field
                                        key={row + col}
                                        fieldId={fieldId}
                                        game={props.openGame}
                                        isSelected={selectedField?.id === fieldId}
                                        selectField={(fieldProps: FieldType) => selectField(fieldProps)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
}

const graphMap = {
    home33: ['a3'],
    home22: ['a3'],
    home23: ['a3'],
    home24: ['a3'],
    home13: ['a3'],
    home37: ['a7'],
    home26: ['a7'],
    home27: ['a7'],
    home28: ['a7'],
    home17: ['a7'],
    home311: ['a11'],
    home210: ['a11'],
    home211: ['a11'],
    home212: ['a11'],
    home111: ['a11'],
    home315: ['a15'],
    home214: ['a15'],
    home215: ['a15'],
    home216: ['a15'],
    home115: ['a15'],
    a1: ['a2', 'b1'],
    a2: ['a1', 'a2'],
    a3: ['a2', 'a4'],
    a4: ['a3', 'a5'],
    a5: ['a4', 'a6', 'b5'],
    a6: ['a5', 'a7'],
    a7: ['a6', 'a8'],
    a8: ['a7', 'a9'],
    a9: ['a8', 'a10', 'b9'],
    a10: ['a9', 'a11'],
    a11: ['a10', 'a12'],
    a12: ['a11', 'a13'],
    a13: ['a12', 'a14', 'b13'],
    a14: ['a13', 'a15'],
    a15: ['a14', 'a16'],
    a16: ['a15', 'a17'],
    a17: ['a16', 'b17'],
    b1: ['a1', 'c1'],
    b5: ['a5', 'c5'],
    b9: ['a9', 'c9'],
    b13: ['a13', 'c13'],
    b17: ['a17', 'c17'],
    c1: ['b1', 'c2'],
    c2: ['c1', 'c2'],
    c3: ['c2', 'c4', 'd3'],
    c4: ['c3', 'c5'],
    c5: ['c4', 'c6'],
    c6: ['c5', 'c7'],
    c7: ['c6', 'c8', 'd7'],
    c8: ['c7', 'c9'],
    c9: ['c8', 'c10'],
    c10: ['c9', 'c11'],
    c11: ['c10', 'c12', 'd11'],
    c12: ['c11', 'c13'],
    c13: ['c12', 'c14'],
    c14: ['c13', 'c15'],
    c15: ['c14', 'c16', 'd15'],
    c16: ['c15', 'c17'],
    c17: ['a17', 'c16'],
    d3: ['c3', 'e3'],
    d7: ['c7', 'e7'],
    d11: ['c11', 'e11'],
    d15: ['c15', 'e15'],
    e3: ['e2', 'e4'],
    e4: ['e3', 'e5'],
    e5: ['e4', 'e6', 'f5'],
    e6: ['e5', 'e7'],
    e7: ['e6', 'e8'],
    e8: ['e7', 'e9'],
    e9: ['e8', 'e10', 'f9'],
    e10: ['e9', 'e11'],
    e11: ['e10', 'e12'],
    e12: ['e11', 'e13'],
    e13: ['e12', 'e14', 'f13'],
    e14: ['e13', 'e15'],
    e15: ['e14', 'e16'],
    f5: ['e5', 'g5'],
    f13: ['e13', 'g13'],
    g5: ['g4', 'g6'],
    g6: ['g5', 'g7'],
    g7: ['g6', 'g8', 'h7'],
    g8: ['g7', 'g9'],
    g9: ['g8', 'g10'],
    g10: ['g9', 'g11'],
    g11: ['g10', 'g12', 'h11'],
    g12: ['g11', 'g13'],
    g13: ['g12', 'g14'],
    h7: ['g7', 'i7'],
    h11: ['g11', 'i11'],
    i7: ['h7', 'i8'],
    i8: ['i7', 'i9'],
    i9: ['i8', 'i10', 'j9'],
    i10: ['i9', 'i11'],
    i11: ['h11', 'i10'],
    j9: ['i9', 'k9'],
    k1: ['k2', 'l1'],
    k2: ['k1', 'k2'],
    k3: ['k2', 'k4'],
    k4: ['k3', 'k5'],
    k5: ['k4', 'k6'],
    k6: ['k5', 'k7'],
    k7: ['k6', 'k8'],
    k8: ['k7', 'k9'],
    k9: ['k8', 'k10', 'j9'],
    k10: ['k9', 'k11'],
    k11: ['k10', 'k12'],
    k12: ['k11', 'k13'],
    k13: ['k12', 'k14'],
    k14: ['k13', 'k15'],
    k15: ['k14', 'k16'],
    k16: ['k15', 'k17'],
    k17: ['k16', 'l17'],
    l1: ['k1', 'm1'],
    l17: ['k17', 'm17'],
    m1: ['m2', 'l1'],
    m2: ['m1', 'm2'],
    m3: ['m2', 'm4'],
    m4: ['m3', 'm5'],
    m5: ['m4', 'm6'],
    m6: ['m5', 'm7'],
    m7: ['m6', 'm8'],
    m8: ['m7', 'm9'],
    m9: ['m8', 'm10', 'n9'],
    m10: ['m9', 'm11'],
    m11: ['m10', 'm12'],
    m12: ['m11', 'm13'],
    m13: ['m12', 'm14'],
    m14: ['m13', 'm15'],
    m15: ['m14', 'm16'],
    m16: ['m15', 'm17'],
    m17: ['m16', 'l17'],
    n9: [],
};

const barriereFields: string[] = ['c1', 'c5', 'c9', 'c13', 'c17', 'g7', 'g11', 'i9', 'j9', 'k9'];
const playerFields: string[][] = [
    ['home33', 'home22', 'home23', 'home24', 'home13'],
    ['home37', 'home26', 'home27', 'home28', 'home17'],
    ['home311', 'home210', 'home211', 'home212', 'home111'],
    ['home315', 'home214', 'home215', 'home216', 'home115'],
];

function Field(props: { fieldId: string; game: MalefizGame; isSelected: boolean; selectField: any }) {
    let classNames: string = '';
    if (Object.keys(graphMap).includes(props.fieldId)) {
        classNames += 'field ';
    }
    if (props.fieldId === 'n9') {
        classNames += 'winner-field border-field';
    } else if (barriereFields.includes(props.fieldId)) {
        classNames += 'barrier-field';
    } else if (playerFields[0].includes(props.fieldId)) {
        classNames += 'player1-field border-field';
    } else if (playerFields[1].includes(props.fieldId)) {
        classNames += 'player2-field border-field';
    } else if (playerFields[2].includes(props.fieldId)) {
        classNames += 'player3-field border-field';
    } else if (playerFields[3].includes(props.fieldId)) {
        classNames += 'player4-field border-field';
    }

    if (classNames) {
        if (props.isSelected) classNames += ' selected';
        for (let i = 0; i < props.game.players.length; i++) {
            const gamePlayer = props.game.players[i];
            const figureId = gamePlayer.figures.findIndex((f) => f === props.fieldId);
            if (figureId >= 0) {
                const fieldProps: FieldType = { id: props.fieldId, figure: { gamePlayerId: i, figureId: figureId } };
                return (
                    <div className={classNames} onClick={() => props.selectField(fieldProps)}>
                        <Figure gamePlayerId={i} />
                    </div>
                );
            }
        }
        const barrierIndex = props.game.barriers.findIndex((b) => b === props.fieldId);
        if (barrierIndex >= 0) {
            const fieldProps: FieldType = { id: props.fieldId, barrierId: barrierIndex };
            return (
                <div className={classNames} onClick={() => props.selectField(fieldProps)}>
                    <Barrier barrierIndex={barrierIndex} />
                </div>
            );
        }
        const fieldProps: FieldType = { id: props.fieldId };
        return <div className={classNames} onClick={() => props.selectField(fieldProps)}></div>;
    } else {
        return <div></div>;
    }
}

function Figure(props: { gamePlayerId: number }) {
    let className = 'figure ';
    if (props.gamePlayerId === 0) {
        className += 'red';
    } else if (props.gamePlayerId === 1) {
        className += 'green';
    } else if (props.gamePlayerId === 2) {
        className += 'yellow';
    } else if (props.gamePlayerId === 3) {
        className += 'blue';
    }

    return <div className={className}></div>;
}

function Barrier(props: { barrierIndex: number | undefined }) {
    let className = 'barrier';
    return <div key={props.barrierIndex} className={className}></div>;
}

function Winner(props: { openGame: MalefizGame; getPlayer: any; endGame: any }) {
    const isWinnerIndex = props.openGame.players.findIndex((p) => p.figures.findIndex((f) => f === 'n9') >= 0);
    if (isWinnerIndex >= 0) {
        return (
            <div style={{ textAlign: 'center' }}>
                <h1>
                    🏅 Gewinner 🏅 <br />
                    {props.getPlayer(props.openGame.players[isWinnerIndex].playerId)}
                </h1>
                <button onClick={() => props.endGame(props.openGame.players[isWinnerIndex].playerId)}>
                    Spiel beenden
                </button>
            </div>
        );
    } else {
        return null;
    }
}

export default Malefiz;
