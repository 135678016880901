import React from 'react';
import firebase from 'firebase';
import './MainChat.css';
import { Player } from '../../App';

export interface ChatEntry {
    id?: string;
    created: string;
    message: string;
    playerid: string;
}

interface ChatProps {
    db: firebase.firestore.Firestore;
    player: Player;
    players: Player[];
}

interface ChatState {
    entries: ChatEntry[];
    message: string;
}

class MainChat extends React.Component<ChatProps, ChatState> {
    unsubChat: any;

    constructor(props: ChatProps) {
        super(props);
        this.state = { entries: [], message: '' };
    }

    componentDidMount() {
        this.unsubChat = this.props.db
            .collection('mainchat')
            .orderBy('created', 'desc')
            .limit(100)
            .onSnapshot((entries) => {
                const entriesArray = entries.docs.map((v, i) => {
                    return { ...{ id: v.id }, ...v.data() };
                }) as Array<ChatEntry>;
                this.setState({ entries: entriesArray });
            });
    }

    componentWillUnmount() {
        console.log('Unmount MainChat');
        this.unsubChat();
    }

    handleChange(event: any) {
        console.log('handleChange:', event.target.value);
        this.setState({ message: event.target.value });
    }

    handleSubmit() {
        console.log('submit: pin', this.state.message);
        if (this.state.message) {
            const playerId = localStorage.getItem('loggedIn');
            this.props.db.collection('mainchat').add({
                playerId: playerId,
                message: this.state.message,
                created: new Date().toISOString(),
            });
            this.setState({ message: '' });
        } else {
            console.log('No message: try again');
        }
    }

    onKeydown(key: any) {
        if (key.keyCode === 13) {
            this.handleSubmit();
        }
    }

    getPlayer(playerId: string): Player {
        return this.props.players.find((player: any) => player.id === playerId) as Player;
    }

    render() {
        return (
            <div className='mainChat'>
                <div>
                    <div className='chatWindow'>
                        {this.state.entries.map((entry: any, index: number) => (
                            <div
                                className={entry.playerId === this.props.player.id ? 'entry self' : 'entry'}
                                key={entry.created}>
                                <div className='player'>
                                    {<PlayerName player={this.getPlayer(entry.playerId)} created={entry.created} />}
                                </div>
                                <div className='message'>{entry.message}</div>
                            </div>
                        ))}
                    </div>
                    <div className='inputArea'>
                        <textarea
                            value={this.state.message}
                            onChange={(e) => this.handleChange(e)}
                            onKeyDown={(k) => this.onKeydown(k)}
                        />
                        <button onClick={() => this.handleSubmit()}>
                            send
                            <br />
                            💬
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainChat;

function PlayerName(props: { player: Player; created: string }) {
    console.log('created:', props.created);
    if (props.player) {
        const date = new Date(props.created);
        return (
            <span>
                {props.player.avatar}
                {props.player.name}:
                <br />
                {date.toLocaleDateString()} {date.getHours()}:{date.getMinutes()} Uhr
            </span>
        );
    } else {
        return <span>Nachricht:</span>;
    }
}
