import React from 'react';
import { Player } from '../../App';
import { EmojiPicker } from '../../lib/pickers';
import firebase from 'firebase';

interface PlayerProps {
    db: firebase.firestore.Firestore;
    player: Player;
    logout: any;
    style: React.CSSProperties | undefined;
}

interface PlayerState {
    showEmojiPickjer: boolean;
}

class PlayerComponent extends React.Component<PlayerProps, PlayerState> {
    constructor(props: PlayerProps) {
        super(props);
        this.state = { showEmojiPickjer: false };
    }

    emojiPicked(emoji: string) {
        console.log('emojiPicked', emoji);
        if (emoji) {
            const playerCopy = JSON.parse(JSON.stringify(this.props.player));
            playerCopy.avatar = emoji;
            delete playerCopy.id;
            this.props.db.collection('players').doc(this.props.player.id).update(playerCopy);
        }
        this.setState({ showEmojiPickjer: false });
    }

    pickEmoji() {
        this.setState({ showEmojiPickjer: true });
    }

    render() {
        return (
            <div style={this.props.style}>
                {this.state.showEmojiPickjer && <EmojiPicker onPick={(emoji: any) => this.emojiPicked(emoji)} />}
                <h2>
                    <span onClick={() => this.pickEmoji()}>{this.props.player.avatar}</span> {this.props.player.name}
                    {'   '}
                    <button onClick={() => this.props.logout()}>logout</button>
                </h2>
            </div>
        );
    }
}

export default PlayerComponent;
