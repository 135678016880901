import React from 'react';
import firebase from 'firebase';
import './Mastermind.css';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { Player } from '../../App';
import MiniChat from '../minichat/MiniChat';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

interface MMGame {
    id?: string;
    created: string;
    winner: string;
    master: string;
    mind: string;
    ended?: boolean;
    solution: {
        solutionPins: string[];
        solutionCommited: boolean;
    };
    row1: {
        guessPins: string[];
        commitedGuess: boolean;
        hintPins: string[];
        commitedHint: boolean;
    };
    row2: {
        guessPins: string[];
        commitedGuess: boolean;
        hintPins: string[];
        commitedHint: boolean;
    };
    row3: {
        guessPins: string[];
        commitedGuess: boolean;
        hintPins: string[];
        commitedHint: boolean;
    };
    row4: {
        guessPins: string[];
        commitedGuess: boolean;
        hintPins: string[];
        commitedHint: boolean;
    };
    row5: {
        guessPins: string[];
        commitedGuess: boolean;
        hintPins: string[];
        commitedHint: boolean;
    };
    row6: {
        guessPins: string[];
        commitedGuess: boolean;
        hintPins: string[];
        commitedHint: boolean;
    };
}

interface MastermindProp {
    db: firebase.firestore.Firestore;
    player: Player;
    players: Player[];
}
interface MastermindState {
    games: MMGame[];
    openGame: MMGame | undefined;
    openPicker: boolean;
    pinElement: any;
    savePinFunction: any;
    pinSetId: string;
    pinIndex: number;
}

class Mastermind extends React.Component<MastermindProp, MastermindState> {
    unsubGames: any;

    constructor(props: MastermindProp) {
        super(props);
        this.state = {
            openGame: undefined,
            games: [],
            openPicker: false,
            savePinFunction: null,
            pinElement: null,
            pinSetId: 'solution',
            pinIndex: -1,
        };
    }

    componentDidMount() {
        this.unsubGames = this.props.db
            .collection('games/mastermind/games')
            .orderBy('created', 'desc')
            .limit(100)
            .onSnapshot((mastermindrows) => {
                let mastermindrowsArray = mastermindrows.docs.map((v, i) => {
                    return { ...{ id: v.id }, ...v.data() };
                }) as MMGame[];
                console.log('onSnapshot games:', mastermindrowsArray);
                const openGame = mastermindrowsArray.find((row: any) => !row.ended) as MMGame | undefined;
                this.setState({ openGame: openGame, games: mastermindrowsArray });
            });
    }

    componentWillUnmount() {
        console.log('Unmount Mastermind games');
        this.unsubGames();
    }

    updateGame(openGame: any) {
        const id = openGame.id;
        delete openGame.id;
        this.props.db.collection('games/mastermind/games').doc(id).update(openGame);
    }

    updatePlayer(player: Player) {
        console.log('updatePlayer', player);
        const id = player.id;
        delete player.id;
        if (id) {
            this.props.db.collection('players').doc(id).update(player);
        } else {
            throw new Error('ID MISSING!!');
        }
    }

    setMaster() {
        console.log('set Master', this.state.openGame, this.props.player);
        if (this.state.openGame && this.props.player.id) {
            const openGame = this.state.openGame;
            if (openGame.mind === this.props.player.id) {
                openGame.mind = '';
            }
            openGame.master = this.props.player.id;
            this.updateGame(openGame);
        }
    }

    setMind() {
        if (this.state.openGame && this.props.player.id) {
            const openGame = this.state.openGame;
            if (openGame.master === this.props.player.id) {
                openGame.master = '';
            }
            openGame.mind = this.props.player.id;
            this.updateGame(openGame);
        }
    }

    commitSolution() {
        if (this.state.openGame && this.props.player.id === this.state.openGame.master) {
            const openGame = this.state.openGame;
            openGame.solution.solutionCommited = true;
            this.updateGame(openGame);
        }
    }

    commitRow(row: number) {
        if (this.state.openGame && this.props.player.id === this.state.openGame.master) {
            this.commitHint(row);
        } else {
            this.commitGuess(row);
        }
    }

    commitGuess(row: number) {
        if (this.state.openGame && this.props.player.id === this.state.openGame.mind) {
            const openGame = this.state.openGame;
            let wrong = undefined;
            switch ('guess' + row) {
                case 'guess1':
                    wrong = openGame.row1.guessPins.find((gp, i) => gp !== openGame.solution.solutionPins[i]);
                    openGame.row1.commitedGuess = true;
                    break;
                case 'guess2':
                    wrong = openGame.row2.guessPins.find((gp, i) => gp !== openGame.solution.solutionPins[i]);
                    openGame.row2.commitedGuess = true;
                    break;
                case 'guess3':
                    wrong = openGame.row3.guessPins.find((gp, i) => gp !== openGame.solution.solutionPins[i]);
                    openGame.row3.commitedGuess = true;
                    break;
                case 'guess4':
                    wrong = openGame.row4.guessPins.find((gp, i) => gp !== openGame.solution.solutionPins[i]);
                    openGame.row4.commitedGuess = true;
                    break;
                case 'guess5':
                    wrong = openGame.row5.guessPins.find((gp, i) => gp !== openGame.solution.solutionPins[i]);
                    openGame.row5.commitedGuess = true;
                    break;
                case 'guess6':
                    wrong = openGame.row6.guessPins.find((gp, i) => gp !== openGame.solution.solutionPins[i]);
                    openGame.row6.commitedGuess = true;
                    break;
            }
            if (wrong === undefined) {
                openGame.winner = this.props.player.id;
            }
            if (row === 6 && wrong) {
                openGame.winner = this.state.openGame.master;
            }

            this.updateGame(openGame);
        }
    }

    commitHint(row: number) {
        if (this.state.openGame && this.props.player.id === this.state.openGame.master) {
            const openGame = this.state.openGame;
            switch ('hint' + row) {
                case 'hint1':
                    openGame.row1.commitedHint = true;
                    break;
                case 'hint2':
                    openGame.row2.commitedHint = true;
                    break;
                case 'hint3':
                    openGame.row3.commitedHint = true;
                    break;
                case 'hint4':
                    openGame.row4.commitedHint = true;
                    break;
                case 'hint5':
                    openGame.row5.commitedHint = true;
                    break;
                case 'hint6':
                    openGame.row6.commitedHint = true;
                    break;
            }
            this.updateGame(openGame);
        }
    }

    setPin(pinSetId: string, pinIndex: number, color: string) {
        console.log('setPin:', pinSetId, pinIndex, color);
        if (this.state.openGame && this.props.player.id) {
            const openGame = this.state.openGame;
            switch (pinSetId) {
                case 'hint1':
                    openGame.row1.hintPins[pinIndex] = color;
                    break;
                case 'hint2':
                    openGame.row2.hintPins[pinIndex] = color;
                    break;
                case 'hint3':
                    openGame.row3.hintPins[pinIndex] = color;
                    break;
                case 'hint4':
                    openGame.row4.hintPins[pinIndex] = color;
                    break;
                case 'hint5':
                    openGame.row5.hintPins[pinIndex] = color;
                    break;
                case 'hint6':
                    openGame.row6.hintPins[pinIndex] = color;
                    break;
                case 'guess1':
                    openGame.row1.guessPins[pinIndex] = color;
                    break;
                case 'guess2':
                    openGame.row2.guessPins[pinIndex] = color;
                    break;
                case 'guess3':
                    openGame.row3.guessPins[pinIndex] = color;
                    break;
                case 'guess4':
                    openGame.row4.guessPins[pinIndex] = color;
                    break;
                case 'guess5':
                    openGame.row5.guessPins[pinIndex] = color;
                    break;
                case 'guess6':
                    openGame.row6.guessPins[pinIndex] = color;
                    break;
                case 'solution':
                    openGame.solution.solutionPins[pinIndex] = color;
                    break;
            }
            this.updateGame(openGame);
        }
    }

    endGame() {
        const openGame = this.state.openGame;
        if (openGame) {
            openGame.ended = true;
            const playerCopy = JSON.parse(JSON.stringify(this.props.players.find((p) => p.id === openGame.winner)));
            playerCopy.gamesWon = playerCopy.gamesWon ? playerCopy.gamesWon + 1 : 1;
            this.updatePlayer(playerCopy);
            this.updateGame(openGame);
        }
    }

    createNewGame() {
        console.log('create new game!');
        this.props.db.collection('games/mastermind/games').add({
            winner: '',
            master: '',
            mind: '',
            created: new Date().toISOString(),
            solution: {
                solutionPins: ['', '', '', ''],
                solutionCommited: false,
            },
            row1: {
                guessPins: ['', '', '', ''],
                commitedGuess: false,
                hintPins: ['', '', '', ''],
                commitedHint: false,
            },
            row2: {
                guessPins: ['', '', '', ''],
                commitedGuess: false,
                hintPins: ['', '', '', ''],
                commitedHint: false,
            },
            row3: {
                guessPins: ['', '', '', ''],
                commitedGuess: false,
                hintPins: ['', '', '', ''],
                commitedHint: false,
            },
            row4: {
                guessPins: ['', '', '', ''],
                commitedGuess: false,
                hintPins: ['', '', '', ''],
                commitedHint: false,
            },
            row5: {
                guessPins: ['', '', '', ''],
                commitedGuess: false,
                hintPins: ['', '', '', ''],
                commitedHint: false,
            },
            row6: {
                guessPins: ['', '', '', ''],
                commitedGuess: false,
                hintPins: ['', '', '', ''],
                commitedHint: false,
            },
        });
    }

    playerIs() {
        if (this.state.openGame && this.state.openGame.master === this.props.player.id) {
            return 'master';
        } else if (this.state.openGame && this.state.openGame.mind === this.props.player.id) {
            return 'mind';
        } else {
            return null;
        }
    }

    onRow() {
        let row = null;
        if (this.state.openGame && this.state.openGame.master && this.state.openGame.mind) {
            row = 'prepare';
            if (this.state.openGame.solution.solutionCommited) {
                row = 'row1';
            }
            if (this.state.openGame.row1.commitedGuess && this.state.openGame.row1.commitedHint) {
                row = 'row2';
            }
            if (this.state.openGame.row2.commitedGuess && this.state.openGame.row2.commitedHint) {
                row = 'row3';
            }
            if (this.state.openGame.row3.commitedGuess && this.state.openGame.row3.commitedHint) {
                row = 'row4';
            }
            if (this.state.openGame.row4.commitedGuess && this.state.openGame.row4.commitedHint) {
                row = 'row5';
            }
            if (this.state.openGame.row5.commitedGuess && this.state.openGame.row5.commitedHint) {
                row = 'row6';
            }
        }
        return row;
    }

    whosTurn() {
        let turn = null;
        if (this.state.openGame && this.state.openGame.master && this.state.openGame.mind) {
            turn = 'master';
            if (this.state.openGame.solution.solutionCommited) {
                turn = 'mind';
            }
            if (this.state.openGame.row1.commitedGuess) {
                turn = 'master';
            }
            if (this.state.openGame.row1.commitedHint) {
                turn = 'mind';
            }
            if (this.state.openGame.row2.commitedGuess) {
                turn = 'master';
            }
            if (this.state.openGame.row2.commitedHint) {
                turn = 'mind';
            }
            if (this.state.openGame.row3.commitedGuess) {
                turn = 'master';
            }
            if (this.state.openGame.row3.commitedHint) {
                turn = 'mind';
            }
            if (this.state.openGame.row4.commitedGuess) {
                turn = 'master';
            }
            if (this.state.openGame.row4.commitedHint) {
                turn = 'mind';
            }
            if (this.state.openGame.row5.commitedGuess) {
                turn = 'master';
            }
            if (this.state.openGame.row5.commitedHint) {
                turn = 'mind';
            }
            if (this.state.openGame.row6.commitedGuess) {
                turn = 'master';
            }
            if (this.state.openGame.row6.commitedHint) {
                turn = 'done';
            }
        }
        return turn;
    }

    giveUp(playerType: string) {
        const openGame = JSON.parse(JSON.stringify(this.state.openGame)) as MMGame;
        if (openGame && openGame.master && openGame.mind) {
            if (playerType === 'master') {
                openGame.winner = openGame.mind;
            } else {
                openGame.winner = openGame.master;
            }
            const playerCopy = JSON.parse(JSON.stringify(this.props.players.find((p) => p.id === openGame.winner)));
            playerCopy.gamesWon = playerCopy.gamesWon ? playerCopy.gamesWon + 1 : 1;
            this.updatePlayer(playerCopy);
            this.updateGame(openGame);
        } else if (openGame) {
            if (playerType === 'master') {
                openGame.master = '';
            } else {
                openGame.mind = '';
            }
            this.updateGame(openGame);
        }
    }

    render() {
        const onRow = this.onRow();
        const playerIs = this.playerIs();
        const turn = this.whosTurn();

        const savePinFunction = (pinSetId: string, pinIndex: number, color: string) => {
            if (pinIndex >= 0) {
                this.setPin(pinSetId, pinIndex, color);
            }
            this.setState({ openPicker: false });
        };

        const pinSelected = (element: any, pinSetId: string, pinIndex: number) => {
            this.setState({
                openPicker: true,
                pinElement: element,
                pinIndex: pinIndex,
                pinSetId: pinSetId,
                savePinFunction: savePinFunction,
            });
        };

        return (
            <div className='mastermind'>
                <MiniChat
                    db={this.props.db}
                    player={this.props.player}
                    players={this.props.players}
                    gameChatUrl={'games/mastermind/chat'}
                />
                <h3 style={{ textAlign: 'center' }}>
                    👨‍🎓 👩‍🎓
                    <br /> Mastermind
                </h3>
                {this.state.openGame ? (
                    <div>
                        {
                            <div className='players'>
                                {this.state.openGame.master ? (
                                    this.props.players.reduce((last: any, p: any) => {
                                        if (this.state.openGame && p.id === this.state.openGame.master) {
                                            return (
                                                <span className={'master' === turn ? 'bold' : ''}>
                                                    Master: {p.avatar}
                                                    {p.name}{' '}
                                                    {this.props.player?.id === p.id && (
                                                        <button onClick={() => this.giveUp('master')}>aufgeben</button>
                                                    )}
                                                </span>
                                            );
                                        } else {
                                            return last;
                                        }
                                    }, '')
                                ) : (
                                    <button onClick={() => this.setMaster()}>Ich bin Master</button>
                                )}
                                <br />
                                {this.state.openGame.mind ? (
                                    this.props.players.reduce((last: any, p: any) => {
                                        if (this.state.openGame && p.id === this.state.openGame.mind) {
                                            return (
                                                <span className={'mind' === turn ? 'bold' : ''}>
                                                    Mind: {p.avatar}
                                                    {p.name}{' '}
                                                    {this.props.player?.id === p.id && (
                                                        <button onClick={() => this.giveUp('mind')}>aufgeben</button>
                                                    )}
                                                </span>
                                            );
                                        } else {
                                            return last;
                                        }
                                    }, '')
                                ) : (
                                    <button onClick={() => this.setMind()}>Ich bin Mind</button>
                                )}
                            </div>
                        }
                        {this.state.openGame.master && this.state.openGame.mind && (
                            <div>
                                {this.state.openGame.winner !== '' && (
                                    <div style={{ textAlign: 'center' }}>
                                        <br />
                                        <h1>
                                            🏅 Gewinner 🏅
                                            <br />
                                            {this.props.players.reduce((last: any, p: any) => {
                                                if (this.state.openGame && p.id === this.state.openGame.winner) {
                                                    return (
                                                        <span>
                                                            {p.avatar}
                                                            {p.name}
                                                        </span>
                                                    );
                                                } else {
                                                    return last;
                                                }
                                            })}
                                        </h1>
                                        <button onClick={() => this.endGame()}>Spiel beenden</button>
                                        <br />
                                    </div>
                                )}
                                <div className='borad-wrapper'>
                                    <div className='board'>
                                        <div className={onRow === 'prepare' ? 'row active' : 'row solution'}>
                                            <div className='number'>Lösung:</div>
                                            <div className='hintPins'></div>
                                            <div className='solutionPins'>
                                                {playerIs === 'master' || this.state.openGame.winner ? (
                                                    <Solution
                                                        pinSelected={pinSelected}
                                                        pins={this.state.openGame.solution.solutionPins}
                                                        pinSetId={'solution'}
                                                    />
                                                ) : (
                                                    <span>
                                                        <span>❔</span>
                                                        <span>❔</span>
                                                        <span>❔</span>
                                                        <span>❔</span>
                                                    </span>
                                                )}
                                            </div>
                                            {onRow === 'prepare' &&
                                                (playerIs === 'master' ? (
                                                    <div
                                                        className='submit-solution'
                                                        onClick={() => this.commitSolution()}>
                                                        🆗
                                                    </div>
                                                ) : (
                                                    <div className='submit-solution'>⏳</div>
                                                ))}
                                        </div>

                                        <div className={onRow === 'row6' ? 'row active' : 'row'}>
                                            <div className='number'>6</div>
                                            <div className='hintPins'>
                                                <Hint
                                                    disabled={onRow !== 'row6' || playerIs === 'mind'}
                                                    pinSelected={pinSelected}
                                                    pins={this.state.openGame.row6.hintPins}
                                                    pinSetId={'hint6'}
                                                />
                                            </div>
                                            <div className='guessPins'>
                                                <Guess
                                                    disabled={onRow !== 'row6' || playerIs === 'master'}
                                                    pinSelected={pinSelected}
                                                    pins={this.state.openGame.row6.guessPins}
                                                    pinSetId={'guess6'}
                                                />
                                            </div>
                                            {onRow === 'row6' &&
                                                (playerIs === turn ? (
                                                    <div className='submit' onClick={() => this.commitRow(6)}>
                                                        🆗
                                                    </div>
                                                ) : (
                                                    <div className='submit'>⏳</div>
                                                ))}
                                        </div>
                                        <div className={onRow === 'row5' ? 'row active' : 'row'}>
                                            <div className='number'>5</div>
                                            <div className='hintPins'>
                                                <Hint
                                                    disabled={onRow !== 'row5' || playerIs === 'mind'}
                                                    pinSelected={pinSelected}
                                                    pins={this.state.openGame.row5.hintPins}
                                                    pinSetId={'hint5'}
                                                />
                                            </div>
                                            <div className='guessPins'>
                                                <Guess
                                                    disabled={onRow !== 'row5' || playerIs === 'master'}
                                                    pinSelected={pinSelected}
                                                    pins={this.state.openGame.row5.guessPins}
                                                    pinSetId={'guess5'}
                                                />
                                            </div>
                                            {onRow === 'row5' &&
                                                (playerIs === turn ? (
                                                    <div className='submit' onClick={() => this.commitRow(5)}>
                                                        🆗
                                                    </div>
                                                ) : (
                                                    <div className='submit'>⏳</div>
                                                ))}
                                        </div>
                                        <div className={onRow === 'row4' ? 'row active' : 'row'}>
                                            <div className='number'>4</div>
                                            <div className='hintPins'>
                                                <Hint
                                                    disabled={onRow !== 'row4' || playerIs === 'mind'}
                                                    pinSelected={pinSelected}
                                                    pins={this.state.openGame.row4.hintPins}
                                                    pinSetId={'hint4'}
                                                />
                                            </div>
                                            <div className='guessPins'>
                                                <Guess
                                                    disabled={onRow !== 'row4' || playerIs === 'master'}
                                                    pinSelected={pinSelected}
                                                    pins={this.state.openGame.row4.guessPins}
                                                    pinSetId={'guess4'}
                                                />
                                            </div>
                                            {onRow === 'row4' &&
                                                (playerIs === turn ? (
                                                    <div className='submit' onClick={() => this.commitRow(4)}>
                                                        🆗
                                                    </div>
                                                ) : (
                                                    <div className='submit'>⏳</div>
                                                ))}
                                        </div>
                                        <div className={onRow === 'row3' ? 'row active' : 'row'}>
                                            <div className='number'>3</div>
                                            <div className='hintPins'>
                                                <Hint
                                                    disabled={onRow !== 'row3' || playerIs === 'mind'}
                                                    pinSelected={pinSelected}
                                                    pins={this.state.openGame.row3.hintPins}
                                                    pinSetId={'hint3'}
                                                />
                                            </div>
                                            <div className='guessPins'>
                                                <Guess
                                                    disabled={onRow !== 'row3' || playerIs === 'master'}
                                                    pinSelected={pinSelected}
                                                    pins={this.state.openGame.row3.guessPins}
                                                    pinSetId={'guess3'}
                                                />
                                            </div>
                                            {onRow === 'row3' &&
                                                (playerIs === turn ? (
                                                    <div className='submit' onClick={() => this.commitRow(3)}>
                                                        🆗
                                                    </div>
                                                ) : (
                                                    <div className='submit'>⏳</div>
                                                ))}
                                        </div>
                                        <div className={onRow === 'row2' ? 'row active' : 'row'}>
                                            <div className='number'>2</div>
                                            <div className='hintPins'>
                                                <Hint
                                                    disabled={onRow !== 'row2' || playerIs === 'mind'}
                                                    pinSelected={pinSelected}
                                                    pins={this.state.openGame.row2.hintPins}
                                                    pinSetId={'hint2'}
                                                />
                                            </div>
                                            <div className='guessPins'>
                                                <Guess
                                                    disabled={onRow !== 'row2' || playerIs === 'master'}
                                                    pinSelected={pinSelected}
                                                    pins={this.state.openGame.row2.guessPins}
                                                    pinSetId={'guess2'}
                                                />
                                            </div>
                                            {onRow === 'row2' &&
                                                (playerIs === turn ? (
                                                    <div className='submit' onClick={() => this.commitRow(2)}>
                                                        🆗
                                                    </div>
                                                ) : (
                                                    <div className='submit'>⏳</div>
                                                ))}
                                        </div>
                                        <div className={onRow === 'row1' ? 'row active' : 'row'}>
                                            <div className='number'>1</div>
                                            <div className='hintPins'>
                                                <Hint
                                                    disabled={onRow !== 'row1' || playerIs === 'mind'}
                                                    pinSelected={pinSelected}
                                                    pins={this.state.openGame.row1.hintPins}
                                                    pinSetId={'hint1'}
                                                />
                                            </div>
                                            <div className='guessPins'>
                                                <Guess
                                                    disabled={onRow !== 'row1' || playerIs === 'master'}
                                                    pinSelected={pinSelected}
                                                    pins={this.state.openGame.row1.guessPins}
                                                    pinSetId={'guess1'}
                                                />
                                            </div>
                                            {onRow === 'row1' &&
                                                (playerIs === turn ? (
                                                    <div className='submit' onClick={() => this.commitRow(1)}>
                                                        🆗
                                                    </div>
                                                ) : (
                                                    <div className='submit'>⏳</div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <Pinpicker
                            openPicker={this.state.openPicker}
                            pinElement={this.state.pinElement}
                            savePin={this.state.savePinFunction}
                            pinSetId={this.state.pinSetId}
                            pinIndex={this.state.pinIndex}
                        />
                    </div>
                ) : (
                    <div>
                        <br />
                        <NewGame newGameCallback={() => this.createNewGame()} />
                        <br />
                        <br />
                        <AllGames games={this.state.games} players={this.props.players} />
                    </div>
                )}
            </div>
        );
    }
}

function NewGame(props: { newGameCallback: any }) {
    return (
        <div>
            <button onClick={() => props.newGameCallback()}>Neues Spiel</button>
        </div>
    );
}

function AllGames(props: { games: MMGame[]; players: Player[] }) {
    const player = (playerId: string, avatar: boolean = false) => {
        const player = props.players.find((p) => p.id === playerId);
        return (
            <span>
                {avatar && player?.avatar} {player?.name}
            </span>
        );
    };

    const datePrint = (datestring: string) => {
        const date = new Date(datestring);
        return date.toLocaleDateString() + ' ' + date.getHours() + ':' + date.getMinutes() + ' Uhr';
    };

    return (
        <div>
            <h5>Gewinner:</h5>
            {props.games.map((game) => (
                <div key={game.id}>
                    <hr />
                    <div>
                        <span style={{ display: 'inline-block' }}>
                            <b>
                                {player(game.master)} (Master) gegen {player(game.mind)} (Mind)
                            </b>
                        </span>
                        <br />
                        <span style={{ display: 'inline-block', width: '50%' }}>{datePrint(game.created)}</span>
                        <span style={{ textAlign: 'right', display: 'inline-block', width: '50%', fontSize: '1.3em' }}>
                            {player(game.winner, true)} 🏅
                        </span>
                    </div>
                </div>
            ))}
            <hr />
        </div>
    );
}

function Solution(props: { pinSetId: string; pins: string[]; pinSelected: any }) {
    return (
        <span>
            {props.pins.map((pin, i) => (
                <span
                    key={i}
                    className={pin ? 'pin' : 'no-pin'}
                    onClick={(event) => props.pinSelected(event.currentTarget, props.pinSetId, i)}>
                    {pin ? pin : '🔘'}
                </span>
            ))}
        </span>
    );
}

function Guess(props: { pinSetId: string; pins: string[]; pinSelected: any; disabled: boolean }) {
    return (
        <div>
            {props.pins.map((pin, i) => (
                <span
                    key={i}
                    className={pin ? 'pin' : 'no-pin'}
                    onClick={(event) => {
                        if (!props.disabled) {
                            props.pinSelected(event.currentTarget, props.pinSetId, i);
                        }
                    }}>
                    {pin ? pin : '🔘'}
                </span>
            ))}
        </div>
    );
}

function Hint(props: { pinSetId: string; pins: string[]; pinSelected: any; disabled: boolean }) {
    return (
        <div>
            {props.pins.map((pin, i) => {
                return (
                    <span
                        key={i}
                        className={pin ? 'pin' : 'no-pin'}
                        onClick={(event) => {
                            if (!props.disabled) {
                                props.pinSelected(event.currentTarget, props.pinSetId, i);
                            }
                        }}>
                        {pin ? pin : '🔘'}
                        {i === 1 && <br />}
                    </span>
                );
            })}
        </div>
    );
}

function Pinpicker(props: { openPicker: boolean; pinElement: any; savePin: any; pinSetId: string; pinIndex: number }) {
    const classes = useStyles();

    // let pinColors = ['🔴', '🟠', '🟡', '🟢', '🔵', '🟣', '⚫️', '⚪️'];
    let pinColors = ['❤️', '🧡', '💛', '💚', '💙', '💜', '⚫️', '⚪️'];
    if (props.pinSetId.includes('hint')) {
        pinColors = ['⚫️', '⚪️', '🔘'];
    }
    const handleClose = (event: any) => {
        console.log('handleClose');
        props.savePin(props.pinSetId, -1, '');
    };

    const handleSelect = (event: any, colorIndex: number) => {
        console.log('handleSelect colorIndex:', pinColors[colorIndex]);
        props.savePin(props.pinSetId, props.pinIndex, pinColors[colorIndex]);
    };

    return (
        <Popover
            id={'color-picker'}
            open={props.openPicker}
            anchorEl={props.pinElement}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
            <Typography className={classes.typography}>
                {pinColors.map((pc, i) => (
                    <span key={i} onClick={(event) => handleSelect(event, i)}>
                        {pc}
                    </span>
                ))}
            </Typography>
        </Popover>
    );
}
export default Mastermind;
