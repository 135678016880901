import React from 'react';
import firebase from 'firebase';
import { EmojiPicker } from '../../lib/pickers';
import { Player } from '../../App';
import './Login.css';

interface LoginPropsType {
    db: firebase.firestore.Firestore;
    players: Array<Player>;
    loggIn: any;
}

interface LoginStateType {
    player: Player | null;
    pin: string | undefined;
    showEmojiPickjer: boolean;
}

class Login extends React.Component<LoginPropsType, LoginStateType> {
    constructor(props: any) {
        super(props);
        this.state = { player: null, pin: '', showEmojiPickjer: false };
    }

    componentDidMount() {}

    playerClicked(player: any) {
        console.log('clicked', player);
        this.setState({ player: player });
    }

    toLogin() {
        this.setState({ player: null });
    }

    handleChange(event: any) {
        console.log('handleChange:', event.target.value);
        this.setState({ pin: event.target.value });
    }

    handleSubmit() {
        console.log('submit: pin', this.state.pin, this.state.player?.pin, this.state.player?.pin === this.state.pin);
        if (!this.state.player?.pin) {
            this.createLogin();
            return;
        }
        if (this.state.player?.pin === this.state.pin) {
            console.log('submit. pin is correct: go to dashboard');
            this.props.loggIn(this.state.player.id);
        } else {
            console.log('pin is wrong: try again');
            this.setState({ pin: undefined });
        }
    }

    createLogin() {
        if (this.state.player?.avatar && this.state.pin && this.state.pin.length > 3) {
            const playerCopy = JSON.parse(JSON.stringify(this.state.player));
            playerCopy.pin = this.state.pin;
            delete playerCopy.id;
            console.log('create new pin:', playerCopy, this.state.player.id);
            this.props.db.collection('players').doc(this.state.player.id).update(playerCopy);
            this.setState({ player: { ...{ id: this.state.player.id }, ...playerCopy } });
        }
    }

    pickEmoji() {
        this.setState({ showEmojiPickjer: true });
    }

    avatarPicked(emoji: string) {
        console.log('emojiPicked', emoji);

        if (emoji) {
            const playerCopy = JSON.parse(JSON.stringify(this.state.player));
            playerCopy.avatar = emoji;
            this.setState({ showEmojiPickjer: false, player: playerCopy });
        } else {
            this.setState({ showEmojiPickjer: false });
        }
    }

    render() {
        console.log('render Login');
        if (this.state.player !== null) {
            return (
                <div className='Login' style={{ textAlign: 'center' }}>
                    {this.state.showEmojiPickjer && <EmojiPicker onPick={(emoji: any) => this.avatarPicked(emoji)} />}
                    <br />
                    <br />
                    {!this.state.player.avatar ? (
                        <button onClick={() => this.pickEmoji()}>Avatar wählen!</button>
                    ) : (
                        <h1 onClick={() => this.pickEmoji()}>{this.state.player.avatar}</h1>
                    )}
                    <h3>
                        {!this.state.player.pin ? 'Hallo ' : 'Login '} {this.state.player.name}
                    </h3>
                    <label>{!this.state.player.pin ? 'Pin erstellen (min. 4 Zahlen)' : 'Pin'}: </label>
                    <br />
                    <br />
                    <input type='number' value={this.state.pin} onChange={(e) => this.handleChange(e)} />
                    <br />
                    <br />
                    <button onClick={() => this.handleSubmit()}>
                        {!this.state.player.pin ? 'speichern' : 'login'}
                    </button>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <button onClick={() => this.toLogin()}>zurück</button>
                </div>
            );
        } else {
            return (
                <div className='Login' style={{ textAlign: 'center' }}>
                    <h3>Spielegge</h3>
                    {this.props.players.map((player, index) => {
                        return (
                            <div key={index} onClick={() => this.playerClicked(player)}>
                                <PlayerListElement player={player} />
                            </div>
                        );
                    })}
                    <br />
                    <br />
                    <br />
                    <div className='footer'>
                        <div className='impressum'>
                            <p>
                                Impressum: <br />
                                Verantwortlicher: Ruben Steinegger; Kontakt: info@spielegge.de
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Login;

function PlayerListElement(props: any) {
    return (
        <div className='Player'>
            <h5>
                {props.player.avatar}&nbsp;
                {props.player.name}&nbsp;
                {props.player.gamesWon > 0 && props.player.gamesWon < 5 && (
                    <span style={{ color: 'gray', fontWeight: 'normal' }}>🥉({props.player.gamesWon})</span>
                )}
                {props.player.gamesWon >= 5 && props.player.gamesWon < 10 && <span>🥈({props.player.gamesWon})</span>}
                {props.player.gamesWon >= 10 && props.player.gamesWon < 20 && <span>🥇({props.player.gamesWon})</span>}
                {props.player.gamesWon >= 20 && <span>🏆({props.player.gamesWon})</span>}
            </h5>
        </div>
    );
}
