import React, { useState } from 'react';
import Dashboard from './pages/dashboard/Dashboard';
import Login from './pages/login/Login';
import firebase from 'firebase';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, useParams } from 'react-router-dom';
import MainChat from './pages/chat/MainChat';
import Mastermind from './pages/mastermind/Mastermind';
import PlayerComponent from './pages/player/Player';
import Malefiz from './pages/malefiz/Malefiz';
import './App.css';

export interface Player {
    id?: string;
    name: string;
    pin: string;
    avatar: string;
    gamesWon?: number;
}

interface AppState {
    loading: boolean;
    playerId: string | null;
    players: Player[];
    ideas: { id: string; message: string; created: string }[];
}

class App extends React.Component<any, AppState> {
    db: firebase.firestore.Firestore;
    unsubscribePlayers: any;
    unsubscribeIdeas: any;

    constructor(pops: any) {
        super(pops);
        this.db = firebase.firestore();
        this.state = { loading: true, playerId: null, players: [], ideas: [] };
    }

    componentDidMount() {
        this.unsubscribePlayers = this.db
            .collection('players')
            .orderBy('gamesWon', 'desc')
            .onSnapshot((players) => {
                const playersArray = players.docs.map((v, i) => {
                    return { ...{ id: v.id }, ...v.data() };
                }) as Player[];
                console.log(playersArray);

                let playerId = localStorage.getItem('loggedIn');
                if (playersArray.findIndex((p) => p.id === playerId) === -1) {
                    playerId = null;
                }
                this.setState({ players: playersArray, playerId: playerId, loading: false });
            });
        this.unsubscribeIdeas = this.db.collection('ideas').onSnapshot((ideas) => {
            const ideasArray = ideas.docs.map((v, i) => {
                return { ...{ id: v.id }, ...v.data() };
            }) as any;
            console.log(ideasArray);
            this.setState({ ideas: ideasArray });
        });
    }

    componentWillUnmount() {
        this.unsubscribeIdeas();
        this.unsubscribePlayers();
    }

    getPlayer(playerId: string) {
        return this.state.players.find((player: any) => player.id === playerId) as Player;
    }

    loggIn(playerId: string) {
        localStorage.setItem('loggedIn', playerId);
        this.setState({ playerId: playerId });
    }

    logout() {
        localStorage.setItem('loggedIn', '');
        this.setState({ playerId: null });
    }

    render() {
        console.log('RENDER APP');

        return (
            <div className='App'>
                <Router>
                    {this.state.playerId ? (
                        <div>
                            <div className='header'>
                                <div className='header-content'>
                                    <PlayerComponent
                                        style={{ display: 'inline-block', width: '65%' }}
                                        db={this.db}
                                        player={this.getPlayer(this.state.playerId)}
                                        logout={() => this.logout()}
                                    />
                                    <div style={{ display: 'inline-block', textAlign: 'right', width: '30%' }}>
                                        <GoHome />
                                    </div>
                                </div>
                            </div>
                            <div className='content'>
                                <Switch>
                                    <Route exact path='/'>
                                        <Dashboard
                                            db={this.db}
                                            player={this.getPlayer(this.state.playerId)}
                                            players={this.state.players}
                                        />
                                    </Route>
                                    <Route exact path='/mastermind'>
                                        <Mastermind
                                            db={this.db}
                                            player={this.getPlayer(this.state.playerId)}
                                            players={this.state.players}
                                        />
                                    </Route>
                                    <Route path='/chat'>
                                        <MainChat
                                            db={this.db}
                                            player={this.getPlayer(this.state.playerId)}
                                            players={this.state.players}
                                        />
                                    </Route>
                                    <Route path='/malefiz'>
                                        <Malefiz
                                            db={this.db}
                                            player={this.getPlayer(this.state.playerId)}
                                            players={this.state.players}
                                        />
                                    </Route>
                                    <Route path='/spielwunsch/:playerId'>
                                        <Spielwunsch
                                            db={this.db}
                                            player={this.getPlayer(this.state.playerId)}
                                            players={this.state.players}
                                            ideas={this.state.ideas}
                                        />
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    ) : (
                        <Login
                            db={this.db}
                            players={this.state.players}
                            loggIn={(playerId: string) => this.loggIn(playerId)}
                        />
                    )}
                </Router>
            </div>
        );
    }
}

function GoHome() {
    const location = useLocation();
    if (location.pathname !== '/') {
        return <Link to='/'>🏠</Link>;
    } else {
        return null;
    }
}

function Spielwunsch(props: {
    db: firebase.firestore.Firestore;
    player: Player;
    players: Player[];
    ideas: { id: string; message: string; created: string }[];
}) {
    console.log('Spielwunsch call');
    let params = useParams<{ playerId: string }>();
    const p = props.players.find((p) => p.id === params.playerId);
    let idea = props.ideas.find((i) => i.id === p?.id);

    const handleSubmit = (message: string) => {
        console.log('submit: pin', message);
        if (message) {
            props.db.collection('ideas').doc(p?.id).set({
                message: message,
                created: new Date().toISOString(),
            });
        } else {
            console.log('No message: try again');
        }
    };

    return (
        <div style={{ padding: '10px' }}>
            <h3>
                {p?.avatar} {p?.name}'s Spielwunsch:
            </h3>

            {p?.id === props.player.id ? (
                <div>
                    <p>
                        🎄Wünsch dir ein Spiel zu Weihnachten!🎄
                        <br />
                        Ruben wird es dann versuchen zu programmieren.
                    </p>
                    {<Whish message={idea ? idea.message : ''} handleSubmit={(m: string) => handleSubmit(m)} />}
                </div>
            ) : (
                <div>
                    <hr />
                    {idea ? idea.message : 'Noch kein Wunsch geäußert'}
                    <hr />
                </div>
            )}
        </div>
    );
}

function Whish(props: { handleSubmit: any; message: string }) {
    const [message, setMessage] = useState(props.message);
    const handleChange = (event: any) => {
        setMessage(event.target.value);
    };
    return (
        <div>
            <textarea value={message} rows={15} onChange={(e) => handleChange(e)} style={{ width: '90%' }}></textarea>
            <br />
            {props.message !== message && <button onClick={() => props.handleSubmit(message)}>speichern</button>}
        </div>
    );
}

export default App;
