import React from 'react';
import firebase from 'firebase';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './Dashboard.css';
import { Player } from '../../App';

interface DashboardProps {
    db: firebase.firestore.Firestore;
    player: Player;
    players: Player[];
}

interface DahsboardState {
    showEmojiPickjer: boolean;
}

class Dashboard extends React.Component<DashboardProps, DahsboardState> {
    constructor(props: any) {
        super(props);
        this.state = { showEmojiPickjer: false };
    }

    componentDidMount() {}

    render() {
        return (
            <div className='dashboard'>
                <div className='dashboard-row'>
                    <div className='dashboard-element'>
                        <Link
                            to='/chat'
                            style={{
                                padding: '10px',
                                borderRadius: '5px',
                                boxShadow: '1px 1px 3px gray',
                                textAlign: 'center',
                                minWidth: '100px',
                                minHeight: '100px',
                                display: 'block',
                            }}>
                            <br />
                            🙋 🙋‍♂️
                            <br />
                            Chat
                        </Link>
                    </div>
                    <div className='dashboard-element'>
                        <Link
                            to='/mastermind'
                            style={{
                                padding: '10px',
                                borderRadius: '5px',
                                boxShadow: '1px 1px 3px gray',
                                textAlign: 'center',
                                minWidth: '100px',
                                minHeight: '100px',
                                display: 'block',
                            }}>
                            <br />
                            👨‍🎓 👩‍🎓
                            <br />
                            Mastermind
                        </Link>
                    </div>
                    <div className='dashboard-element'>
                        <Link
                            to='/malefiz'
                            style={{
                                padding: '10px',
                                borderRadius: '5px',
                                boxShadow: '1px 1px 3px gray',
                                textAlign: 'center',
                                minWidth: '100px',
                                minHeight: '100px',
                                display: 'block',
                            }}>
                            <br />
                            👧 👨
                            <br /> 👩 👦
                            <br />
                            Malefiz
                        </Link>
                    </div>
                    {this.props.players.map((player: Player) => {
                        return player.pin ? (
                            <div className='dashboard-element'>
                                <Link
                                    to={'/spielwunsch/' + player.id}
                                    style={{
                                        padding: '10px',
                                        borderRadius: '5px',
                                        boxShadow: '1px 1px 3px gray',
                                        textAlign: 'center',
                                        minWidth: '100px',
                                        minHeight: '100px',
                                        display: 'block',
                                    }}>
                                    <br />
                                    Spielwunsch
                                    <br />
                                    {player.avatar} {player.name}
                                </Link>
                            </div>
                        ) : null;
                    })}
                </div>
            </div>
        );
    }
}

export default Dashboard;
