import React from 'react';
import firebase from 'firebase';
import { Player } from '../../App';
import './MiniChat.css';

export interface ChatEntry {
    id?: string;
    created: string;
    message: string;
    playerid: string;
}

interface ChatProps {
    db: firebase.firestore.Firestore;
    player: Player;
    players: Player[];
    gameChatUrl: string;
}

interface ChatState {
    entries: ChatEntry[];
    message: string;
    expanded: boolean;
    newEntries: number;
}

class MiniChat extends React.Component<ChatProps, ChatState> {
    unsubChat: any;
    unsubNewEntries: any;

    constructor(props: ChatProps) {
        super(props);
        this.state = { entries: [], message: '', expanded: false, newEntries: 0 };
    }

    componentDidMount() {
        this.subscribeNewEntries();
    }

    subscribeGameChat() {
        this.unsubChat = this.props.db
            .collection(this.props.gameChatUrl)
            .orderBy('created', 'desc')
            .limit(100)
            .onSnapshot((entries) => {
                const entriesArray = entries.docs.map((v, i) => {
                    return { ...{ id: v.id }, ...v.data() };
                }) as Array<ChatEntry>;
                this.setState({ entries: entriesArray });
            });
    }

    subscribeNewEntries() {
        const now = new Date().toISOString();
        this.unsubNewEntries = this.props.db
            .collection(this.props.gameChatUrl)
            .orderBy('created', 'desc')
            .limit(100)
            .endAt(now)
            .onSnapshot((entries) => {
                console.log('new message', entries.docs);
                this.setState({ newEntries: entries.docs.length });
            });
    }

    componentWillUnmount() {
        console.log('Unmount MainChat');
        try {
            this.unsubChat();
        } catch (e) {
            console.warn(e);
        }

        try {
            this.unsubNewEntries();
        } catch (e) {
            console.warn(e);
        }
    }

    toggle() {
        const showChat = !this.state.expanded;
        if (showChat) {
            this.subscribeGameChat();
            this.unsubNewEntries();
        } else {
            this.subscribeNewEntries();
            this.unsubChat();
        }
        this.setState({ expanded: showChat });
    }

    handleChange(event: any) {
        console.log('handleChange:', event.target.value);
        this.setState({ message: event.target.value });
    }

    handleSubmit() {
        console.log('submit: pin', this.state.message);
        if (this.state.message) {
            const playerId = localStorage.getItem('loggedIn');
            this.props.db.collection(this.props.gameChatUrl).add({
                playerId: playerId,
                message: this.state.message,
                created: new Date().toISOString(),
            });
            this.setState({ message: '' });
        } else {
            console.log('No message: try again');
        }
    }

    onKeydown(key: any) {
        if (key.keyCode === 13) {
            this.handleSubmit();
        }
    }

    getPlayer(playerId: string): Player {
        return this.props.players.find((player: any) => player.id === playerId) as Player;
    }

    render() {
        return (
            <div className='miniChat'>
                {this.state.expanded === false ? (
                    <div className='miniChat-collapsed'>
                        Game Chat:
                        <span className='expand' onClick={() => this.toggle()}>
                            👁‍🗨
                        </span>
                        {this.state.newEntries > 0 && <span className='indicator'>{this.state.newEntries}</span>}
                    </div>
                ) : (
                    <div>
                        <div className='miniChat-collapsed'>
                            Game Chat:
                            <span className='expand' onClick={() => this.toggle()}>
                                ✖️
                            </span>
                        </div>
                        <div className='miniChatWindow'>
                            {this.state.entries.map((entry: any, index: number) => (
                                <div
                                    className={entry.playerId === this.props.player.id ? 'entry self' : 'entry'}
                                    key={entry.created}>
                                    <div className='player'>
                                        {<PlayerName player={this.getPlayer(entry.playerId)} created={entry.created} />}
                                    </div>
                                    <div className='message'>{entry.message}</div>
                                </div>
                            ))}
                        </div>
                        <div className='inputArea'>
                            <textarea
                                rows={1}
                                value={this.state.message}
                                onChange={(e) => this.handleChange(e)}
                                onKeyDown={(k) => this.onKeydown(k)}
                            />
                            <button onClick={() => this.handleSubmit()}>send</button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default MiniChat;

function PlayerName(props: { player: Player; created: string }) {
    if (props.player) {
        const date = new Date(props.created);
        return (
            <span>
                {props.player.avatar}
                {props.player.name}:
            </span>
        );
    } else {
        return <span>Nachricht:</span>;
    }
}
