import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: 'AIzaSyCjDjGYYUNfcdq169IXW1MrWfpD1zHjT6M',
    authDomain: 'spielegge-9a360.firebaseapp.com',
    databaseURL: 'https://spielegge-9a360.firebaseio.com',
    projectId: 'spielegge-9a360',
    storageBucket: 'spielegge-9a360.appspot.com',
    messagingSenderId: '1075937550193',
    appId: '1:1075937550193:web:ca36ce17dbce1501e560d0',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
